import React from 'react';
import newregistrationlaptop from '../../assets/images/newregistrationlaptop.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import api from '../../api/api';
import CommonButton from '../commonButton/CommonButton';
import Cookies from 'js-cookie';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Name should only contain letters.')
        .required('Name is required.'),
    email: Yup.string()
        .email('Please enter a valid email address.')
        .required('Email is required.'),
    mobileNumber: Yup.string()
        .matches(/^[0-9]{10}$/, 'Please enter a valid 10-digit mobile number.')
        .required('Mobile number is required.'),
});

const ClientRegistration = ({ onSuccess }) => {
    const navigate = useNavigate();

    const handleSubmit = async (values, { resetForm }) => {
        try {
            const formValues = {
                name: values.name,
                email: values.email,
                phone: values.mobileNumber,
                country: '1',
            };

            const response = await api.post(`/auth/createUser`, formValues);
            // console.log('API Response:', response);

            if (response.status === 200 && response.data.success) {
                const { userid, rolee } = response.data.data;

                // Store cookies
                Cookies.set('id', userid, { secure: true, sameSite: 'Strict' });
                Cookies.set('rolee', rolee, { secure: true, sameSite: 'Strict' });

                // Success notification
                notification.success({
                    message: response.data.message || 'User created successfully and email sent!',
                });

                // Reset form and navigate
                resetForm();
                navigate('/check-out');

                // Callback function if provided
                if (onSuccess) {
                    onSuccess();
                }
            } else {
                notification.error({
                    message: response.data.message || 'Failed to create user.',
                });
            }
        } catch (error) {
            if (error.response && error.response.data) {
                notification.error({
                    message: error.response.data.message || 'Something went wrong.',
                });
            } else {
                notification.error({ message: 'Something went wrong.' });
            }
        }
    };

    return (
        <div className="container" style={{ marginTop: '150px' }}>
            <div className="newregistrationClientMain">
                {/* Left Image Box */}
                <div className="registrationLeftBox col-md-6">
                    <img src={newregistrationlaptop} alt="laptop" />
                </div>

                {/* Right Registration Box */}
                <div className="registrationLeftRight col-md-6">
                    <h1>Pre Order Registration</h1>
                    <div className="registrationLeftRight-inner">
                        <Formik
                            initialValues={{
                                name: '',
                                email: '',
                                mobileNumber: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            <Form>
                                <div className="col-md-12">
                                    <Field name="name"
                                     className="newregistartion-form" placeholder="Full Name" />
                                    <ErrorMessage name="name" component="span" className="error" />
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-6 col-sm-12">
                                        <Field name="email" className="newregistartion-form" placeholder="Email Id" />
                                        <ErrorMessage name="email" component="span" className="error" />
                                    </div>

                                    <div className="form-group col-md-6 col-sm-12">
                                        <Field
                                            name="mobileNumber"
                                            className="newregistartion-form"
                                            placeholder="Phone Number"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            maxLength="10"
                                        />
                                        <ErrorMessage name="mobileNumber" component="span" className="error" />
                                    </div>
                                </div>

                                <div className="newAgreeterm-policy">
                                    <label>
                                        <input
                                            type="checkbox"
                                            required
                                            style={{ marginRight: '5px' }}
                                        />
                                        By clicking Select Pre Order, you are agreeing to the
                                        <a href="/terms-and-conditions" style={{ padding: '6px' }}>
                                            terms of use
                                        </a>{' '}
                                        and acknowledging the
                                        <a href="/privacy-policy" style={{ padding: '6px' }}>
                                            privacy policy
                                        </a>
                                        .
                                    </label>
                                </div>

                                <div className="form-group col-md-6">
                                    <label className="new-paymentaccepted">Payment accepted via UPI</label>
                                    <div className="payment-methods">
                                        <img
                                            src="upi-payment.png"
                                            alt="UPI Payment Method"
                                            className="payment-methods-icon-image"
                                            loading="lazy"
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <CommonButton buttonText="Select Pre Order With Laptop" type="submit" />
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientRegistration;
