import React from "react";
import PageTitle from "../../../components/pageTitle/PageTitle";
import Layout from "../../../components/Layout/Layout";
import CommonButton from "../../commonButton/CommonButton";
import { useNavigate } from "react-router-dom";

const PaymentFailed = () => {
    
      const navigate = useNavigate();
    
      const handleChange =()=>{
        navigate("/check-out")
      }
    return (
        <>
            <Layout>
                <PageTitle
                    gredTitle="Thank you ! Your Payment failed"
                />
                <div className="paymentsuccessfull  boxWrpper">
                    <div className="modal-content">
                        <p>Thank you for your payment! Your transaction was Failed.</p>

                        <div className="paymentsuccessfull-btn">
                            <CommonButton
                                buttonText="My Orders"
                                type="button"
                                onClick={handleChange}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default PaymentFailed;