import React from "react";
import Sliders from "../FeaturedSlider/Sliders";
import SlideOne from "../FeaturedSlider/SlideOne";
// import SlideTwo from "../FeaturedSlider/SlideTwo";
import CommonButton from "../commonButton/CommonButton";
import { useNavigate } from "react-router-dom";

function FeatureSliderSection() {
    const slides = [
        <div key={1}><SlideOne /></div>,
        // <div key={1}><SlideTwo /></div>,
    ];

      const navigate = useNavigate()
      
      const orderproduct =()=>{
        navigate('/client-registration');
      }
    
    return (
        <div className="container">
      <div className="row">
        <div className="col-lg-12  mx-auto">
          <div className="sectionTitleWrap">
            <h2>Solaris Book 14X OLED</h2>
            <p>
              Set your imagination free with the powerful Solaris Book 14X OLED!
              Whether it's for creative work or gaming fun
            </p>
          </div>
          <div className="slider-container">
            <Sliders slides={slides} />
          </div>
        </div>
      </div>
      <div className="slider-container-btn featureSlideRight" >
        <CommonButton
          buttonText="Pre Order With Digital Course"
          type="button"
          // modal="modal"
          onClick={orderproduct}
          modalId="#staticBackdrop"
        /> 
      </div>
    </div>
    );
}

export default FeatureSliderSection;