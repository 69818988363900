import React, { useEffect, useState } from "react";
import { logo } from "..";
import { Link, useLocation } from "react-router-dom";
import CommonButton from "../commonButton/CommonButton";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import { Modal } from 'antd'


function Navbar() {
  ReactGA.initialize('G-DM72HCGGSN');
  const [scroll, setScroll] = useState(false);
  const [prevScrollpos, setPrevScrollpos] = useState(0);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setIsNavOpen(false);
    const handleScroll = () => {
      setScroll(window.scrollY > 0);
      const currentScrollpos = window.pageYOffset;
      setPrevScrollpos(currentScrollpos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollpos]);

  const getLinkStyle = (path) => {
    return location.pathname === path
      ? { color: "#000000", fontWeight: "bold" }
      : {};
  };

  const id = Cookies.get('id');
  const affiliateId = Cookies.get('affiliateId');
  const role3 = Cookies.get("role");

  const handleLogout = () => {
    Cookies.remove('name');
    Cookies.remove('id');
    Cookies.remove('affiliateId');
    Cookies.remove('role3')
    Cookies.remove('token');
    Cookies.remove("paymentstatus");
    Cookies.remove('');
    notification.success({ message: "Logout Successful" });
    navigate("/");
    window.location.reload('');
  };

  const handleLogoutAffiliate = () => {
    Cookies.remove('name');
    Cookies.remove('id');
    Cookies.remove('affiliateId');
    Cookies.remove('role3')
    Cookies.remove('token');
    Cookies.remove('role');
    Cookies.remove('');
    notification.success({ message: "Logout Successful" });
    navigate("/");
    window.location.reload('');
  }

  //  *******************************
  const Registration = () => {
    const paymentstatus = Cookies.get("paymentstatus");
    const id = Cookies.get("id");
    if (paymentstatus === "1") {
      setIsModalVisible(true);
    } else if (id && !paymentstatus) {
      navigate("/check-out");
    } else {
      navigate("/client-registration");
    }
  };
  
  const handleOk = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      {/* *************************************** */}
      <header id="header" className={scroll ? "header" : "header topClass"}>
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="solaris logo" className="img-fluid" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              aria-controls="navbarSupportedContent"
              aria-expanded={isNavOpen ? "true" : "false"}
              aria-label="Toggle navigation"
              id="menuToggle"
              onClick={() => setIsNavOpen(!isNavOpen)}
            >
              <input type="checkbox" checked={isNavOpen} readOnly />
              <span></span>
              <span></span>
              <span></span>
            </button>
            <div className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`} id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    to="/"
                    style={getLinkStyle("/")}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/about-us"
                    style={getLinkStyle("/about-us")}
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/affiliate"
                    style={getLinkStyle("/affiliate")}
                  >
                    Affiliate
                  </Link>
                </li>
                <li className="nav-item rightBorder">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    to="/support"
                    style={getLinkStyle("/support")}
                  >
                    Support
                  </Link>
                </li>
                {id ? ( // Check if id exists in cookies
                  <>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        aria-current="page"
                        to="/my-account"
                        style={getLinkStyle("/my-account")}
                      >
                        My Orders

                      </Link>

                    </li>
                    <li className="nav-item">
                      <button className="nav-link logout-btn" onClick={handleLogout}>
                        Logout
                      </button>
                    </li>
                  </>
                ) : affiliateId ? (
                  <>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        aria-current="page"
                        to="/affiliate-details"
                        style={getLinkStyle("/affiliate-details")}
                      >
                        My Affiliate Dashboard
                      </Link>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link logout-btn" onClick={handleLogoutAffiliate}>
                        Logout
                      </button>
                    </li>
                  </>
                ) : (
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      aria-current="page"
                      to="/login"
                      style={getLinkStyle("/login")}
                    >
                      Login
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 extraLinks">
              <li className="nav-item">
                <CommonButton
                  buttonText="Order Now"
                  type="button"
                  // modal="modal"
                  onClick={Registration}
                  modalId="#staticBackdrop"
                />
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {/* modal******************************************* */}

      {/* Modal */}
      <Modal
      className="eachusersingleproduct-order"
        // title="Notice"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleOk}
        footer={null}
      >
        <div className="section step3">
          <h1> Each user can place a maximum of one order to ensure that< br /> all Indians have the opportunity to benefit from the scheme.</h1>
        </div>
      </Modal>

    </>

  );
}
export default Navbar;
