// Login.js
import React, { useState } from 'react';
import { Formik } from 'formik';
import api from '../../../src/api/api';
import { Button, Modal, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useAuth } from './AuthContext';
import { Toaster } from 'react-hot-toast';
import ResetPassword from '../../ResetPassword/ResetPassword';
import { Helmet } from 'react-helmet';

const Login = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="login-container" style={{ marginTop: "140px" }}>
            
            <Helmet>
                <title>Login | Solaris</title>
                <meta name="description" content="Log in to your Solaris account to view your digital courses, manage orders, and access exclusive content tailored to support your learning journey." />
                <meta name="keywords" content="Solaris Login, Access Courses, Account Login" />
                <meta name="author" content="Solaris Comtech" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://solarisbook.com/login" />
                <meta property="og:title" content="Login to Your Solaris Account | Access Your Courses" />
                <meta property="og:description" content="Log in to your Solaris account to view your digital courses, manage orders, and access exclusive content tailored to support your learning journey." />
                <meta property="og:image" content="https://solarisbook.com/Solaris-Book-Laptop.png" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://solarisbook.com/login" />
                <meta name="twitter:title" content="Login to Your Solaris Account | Access Your Courses" />
                <meta name="twitter:description" content="Log in to your Solaris account to view your digital courses, manage orders, and access exclusive content tailored to support your learning journey." />
                <meta name="twitter:image" content="https://solarisbook.com/Solaris-Book-Laptop.png" />
            </Helmet>

            <h1>Please Login!</h1>
            <Formik
                initialValues={{ email: '', password: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Required';
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                        errors.email = 'Invalid email address';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    api.post(`/auth/signIn`, {
                        email: values.email,
                        password: values.password,
                    })
                        .then((response) => {
                            const user = response.data.data;
                            Cookies.set('role', user.role, { secure: true, sameSite: 'Strict' });
                            Cookies.set('idd', user.id, { secure: true, sameSite: 'Strict' });

                            if (user.role !== 1) {
                                Cookies.set('rolee', user.rolee, { secure: true, sameSite: 'Strict' });
                                Cookies.set('id', user.userid, { secure: true, sameSite: 'Strict' });
                            } else {
                                Cookies.remove('rolee');
                                Cookies.remove('id');
                            }
                            notification.success({ message: 'Sign-in successful!' });
                            login();
                            if (user.role === 1) {
                                navigate("/dashboard");
                            } else {
                                navigate("/my-account");
                                setTimeout(() => {
                                    window.location.reload();
                                }, 100);
                            }
                        })
                        .catch((error) => {
                            if (error.response && error.response.data && error.response.data.message) {
                                notification.error({ message: error.response.data.message });
                            } else {
                                notification.error({ message: 'Login failed. Please try again.' });
                            }
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form className="login-form" onSubmit={handleSubmit}>
                        <input
                            type="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            placeholder="Email"
                        />
                        {errors.email && touched.email && <div className="error-message">{errors.email}</div>}
                        <input
                            type="password"
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            placeholder="Password"
                        />
                        {errors.password && touched.password && <div className="error-message">{errors.password}</div>}
                        <button type="submit" disabled={isSubmitting}>
                            Login
                        </button>
                        <Button
                        type="primary"
                            style={{
                                marginTop: "10px",
                                paddingBottom:"21px",
                                paddingTop:"21px",
                                backgroundColor: "#007bff",
                                color: "white" ,
                            }}
                            onClick={showModal}
                        >
                          <p style={{color:"white", paddingTop:"6px"}}> Reset Password</p> 
                        </Button>
                    </form>
                )}
            </Formik>
            <Toaster position="bottom-left" reverseOrder={false} />

            <Modal title="Reset Password" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                <ResetPassword onSuccess={handleCancel} />
            </Modal>
        </div>
    );
};

export default Login;
