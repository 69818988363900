import React, { useEffect, useState } from 'react';
import api, { BASE_URL } from '../api/api';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrView } from "react-icons/gr";
import { Modal } from 'antd';

const AdminDashboardBankVerify = () => {
    const [bankVerify, setBankVerify] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedProof, setSelectedProof] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    // Edit Modal States
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [editData, setEditData] = useState({});

    const image_url = `${BASE_URL}/upload/affiliate/bank/${selectedProof}`;

    useEffect(() => {
        fetchAffiliates();
    }, []);

    const fetchAffiliates = async () => {
        try {
            const res = await api.get("/affiliate/getAllAffiliates");
            setBankVerify(res.data.data);
        } catch (error) {
            console.error("Error fetching affiliates:", error);
        }
    };

    const handleViewClick = (id) => {
        const selectedBank = bankVerify.find(bank => bank.id === id);
        setSelectedId(id);
        setSelectedProof(selectedBank.bank_proof);
        setSelectedData(selectedBank);
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setSelectedProof(null);
    };

    const handleSearch = async () => {
        if (searchQuery.trim()) {
            try {
                const { data } = await api.get(`/affiliate/searchAffiliates`, { params: { query: searchQuery } });
                setBankVerify(data.data || []);
            } catch (error) {
                console.error('Error searching affiliates:', error);
            }
        } else {
            fetchAffiliates();
        }
    };

    const handleEdit = (affiliate) => {
        setEditData(affiliate);
        setIsEditModalVisible(true);
    };

    const handleEditSubmit = async () => {
        try {
            const updatedBankData = {
                email: editData.email,
                bank_name: editData.bank_verify === 0 ? null : editData.bank_name,
                bankac: editData.bank_verify === 0 ? null : editData.bankac,
                ifsc: editData.bank_verify === 0 ? null : editData.ifsc,
                inform: editData.inform,
                bank_verify: Number(editData.bank_verify),
            };

            // console.log("Submitting data:", updatedBankData);

            await api.put(`/affiliate/updateBankInfoById/${editData.id}`, updatedBankData);
            fetchAffiliates();
            setIsEditModalVisible(false);
        } catch (error) {
            console.error("Error updating affiliate bank info:", error);
        }
    };


    const handleDelete = async (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this affiliate? Press Okay or Cancel");
        if (confirmed) {
            try {
                await api.delete(`/affiliate/deleteAffiliate/${id}`);
                fetchAffiliates();
            } catch (error) {
                console.error("Error deleting affiliate:", error);
            }
        }
    };



    return (
        <div className='dashboard-container'>
            <h1>Bank Verification</h1>
            <div className='search-container'>
                <input
                    type="text"
                    placeholder='Search User'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button onClick={handleSearch}>Search</button>
            </div>
            <h2>Bank Verification</h2>
            <div className="table-containereee">
                <table className='adminuser-table'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Bank Name</th>
                            <th>Account No</th>
                            <th>IFSC Code</th>
                            <th>Bank Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bankVerify.map((bank) => (
                            <tr key={bank.id}>
                                <td>{bank.name}</td>
                                <td>{bank.email}</td>
                                <td>{bank.phone}</td>
                                <td>{bank.bank_name}</td>
                                <td>{bank.bankac}</td>
                                <td>{bank.ifsc}</td>
                                <td>{bank.bank_verify}</td>
                                <td>
                                    <FaEdit style={{ fontSize: "15px", cursor: 'pointer' }} onClick={() => handleEdit(bank)} title='Edit Bank Details' />
                                    <MdDelete style={{ fontSize: "15px", cursor: 'pointer' }} onClick={() => handleDelete(bank.id)} title='Delete Bank Detail' />
                                    <GrView title='View Bank Details'
                                        style={{ fontSize: "15px", cursor: 'pointer' }}
                                        onClick={() => handleViewClick(bank.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Modal
                title={`Bank Proof for ID: ${selectedId}`}
                open={isModalVisible}
                onCancel={handleCloseModal}
                footer={null}
                width={800}
            >
                {selectedData ? (
                    <div>
                        <table className="affiliate-table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Address</th>
                                    <th>Bank Name</th>
                                    <th>Account No</th>
                                    <th>IFSC Code</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{selectedData.name}</td>
                                    <td>{selectedData.email}</td>
                                    <td>{selectedData.phone}</td>
                                    <td>{selectedData.address}</td>
                                    <td>{selectedData.bank_name}</td>
                                    <td>{selectedData.bankac}</td>
                                    <td>{selectedData.ifsc}</td>
                                </tr>
                            </tbody>
                        </table>

                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            {selectedProof ? (
                                selectedProof.endsWith('.pdf') ? (
                                    <iframe
                                        src={image_url}
                                        style={{ width: '100%', height: '400px' }}
                                        title="Bank Proof"
                                    />
                                ) : (
                                    <img
                                        src={image_url}
                                        alt="Bank Proof"
                                        style={{ maxWidth: '100%', maxHeight: '400px' }}
                                    />
                                )
                            ) : (
                                <p>No bank proof available.</p>
                            )}
                        </div>
                    </div>
                ) : (
                    <p>Loading bank details...</p>
                )}
            </Modal>

            {/* Edit Modal */}
            <Modal
                title="Edit Affiliate Bank Info"
                open={isEditModalVisible}
                onCancel={() => setIsEditModalVisible(false)}
                onOk={handleEditSubmit}
            >
                <form>
                    <div className="row g-3">
                        <div className="col-md-6">
                            <input
                                className='bankverifyDashboard'
                                type="text"
                                value={editData.email || ''}
                                readOnly // Email should be read-only
                                placeholder="Email"
                            />
                        </div>
                        <div className="col-md-6">
                            <input
                                className='bankverifyDashboard'
                                type="text"
                                value={editData.bank_name || ''}
                                onChange={(e) => setEditData({ ...editData, bank_name: e.target.value })}
                                placeholder="Bank Name"
                            />
                        </div>
                        <div className="col-md-6">
                            <input
                                className='bankverifyDashboard'
                                type="text"
                                value={editData.bankac || ''}
                                onChange={(e) => setEditData({ ...editData, bankac: e.target.value })}
                                placeholder="Account No"
                            />
                        </div>
                        <div className="col-md-6">
                            <input
                                className='bankverifyDashboard'
                                type="text"
                                value={editData.ifsc || ''}
                                onChange={(e) => setEditData({ ...editData, ifsc: e.target.value })}
                                placeholder="IFSC Code"
                            />
                        </div>
                        <div className="col-md-6">
                            <select
                                className='bankverifyDashboard'
                                value={editData.bank_verify === 1 ? '1' : editData.bank_verify === 0 ? '0' : '2'}
                                onChange={(e) => {
                                    const value = Number(e.target.value);
                                    setEditData((prev) => ({
                                        ...prev,
                                        bank_verify: value,
                                        bank_name: value === 0 ? null : prev.bank_name,
                                        bankac: value === 0 ? null : prev.bankac,
                                        ifsc: value === 0 ? null : prev.ifsc,
                                    }));
                                }}
                            >
                                <option value="1">Active</option>
                                <option value="2">Pending</option>
                                <option value="0">Wrong Bank Statement</option>
                            </select>
                        </div>

                        <div className="col-md-6">
                            {editData.bank_verify === 0 ? (
                                <textarea
                                    className='bankverifyDashboard'
                                    value={editData.inform || ''}
                                    onChange={(e) => setEditData({ ...editData, inform: e.target.value })}
                                    placeholder="send information"
                                />
                            ) : null}
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    );
};
export default AdminDashboardBankVerify;
