import React, { useState, useEffect } from 'react';
import api from '../api/api';
import Modal from 'react-modal';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrView } from "react-icons/gr";

const AdminOrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [editingOrderId, setEditingOrderId] = useState(null);
    const [updatedData, setUpdatedData] = useState({
        UserName: '', ProductName: '', AffilateName: '', DigitalCourse: '', GatewayAmount: '',
        PaymentStatus: ""
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [viewOrderData, setViewOrderData] = useState(null);


    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalOrders, setTotalOrders] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedOrders, setSelectedOrders] = useState(new Set());

    const fetchOrders = async (page, limit) => {
        try {
            const { data } = await api.get(`/orders/getAllOrders`, {
                params: { page, limit }
            });
            setOrders(data.data || []);
            setTotalOrders(data.totalOrders || 0);
            setTotalPages(data.totalPages || 1);
        } catch (error) {
            // Handle error
        }
    };

    useEffect(() => {
        fetchOrders(currentPage, itemsPerPage);
    }, [currentPage, itemsPerPage]);

    const openEditModal = (order) => {
        setEditingOrderId(order.id);
        setUpdatedData({
            UserName: order.UserName,
            ProductName: order.ProductName,
            AffilateName: order.AffilateName,
            DigitalCourse: order.DigitalCourse,
            GatewayAmount: order.GatewayAmount,
            PaymentStatus: order.PaymentStatus,
        });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setEditingOrderId(null);
        setUpdatedData({
            UserName: '', ProductName: '',
            AffilateName: '',
            DigitalCourse: '',
            GatewayAmount: '',
            PaymentStatus: '',
        });
    };

    const handleSearch = async () => {
        if (searchQuery.trim()) {
            try {
                const { data } = await api.get(`/orders/searchOrders`, {
                    params: { query: searchQuery }
                });
                setOrders(data.data || []);
                setTotalOrders(data.totalOrders || 0);
                setTotalPages(data.totalPages || 1);
                setCurrentPage(1);
            } catch (error) {
                // Handle error
            }
        } else {
            fetchOrders(currentPage, itemsPerPage);
        }
    };

    const handleEdit = async () => {
        try {
            await api.put(`/orders/editOrder/${editingOrderId}`, updatedData);
            setOrders((prev) =>
                prev.map((order) =>
                    order.id === editingOrderId
                        ? { ...order, ...updatedData }
                        : order
                )
            );
            closeModal();
        } catch (error) {
          
        }
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Would you like to delete this order? Press Okay or Cancel");
        if (confirmDelete) {
            try {
                await api.delete(`/orders/deleteOrder/${id}`);
                fetchOrders(currentPage, itemsPerPage);
            } catch (error) {
                
            }
        } else {
            alert("Order deletion canceled");
        }
    };

    const handleDeleteSelected = async () => {
        if (selectedOrders.size === 0) {
            alert("No orders selected for deletion.");
            return;
        }

        const confirmDelete = window.confirm(`Would you like to delete ${selectedOrders.size} selected order(s)? Yes / No`);
        if (confirmDelete) {
            try {
                const ordersToDelete = Array.from(selectedOrders);
                await Promise.all(ordersToDelete.map(id => api.delete(`/orders/deleteOrder/${id}`)));
                fetchOrders(currentPage, itemsPerPage);
                setSelectedOrders(new Set());
            } catch (error) {
                // Handle error
            }
        } else {
            alert("Order deletion canceled");
        }
    };

    const handleView = async (id) => {
        try {
            const {data} = await api.get(`/orders/getOrderByIdAdmin/${id}`);
            // console.log("data",data);
            setViewOrderData(data.data); 
            setViewModalOpen(true);
        } catch (error) {
            
        }
    };

    // useEffect(()=>{
    //     api.get(`/orders/getUserOrders/37`)
    //     .then((res)=>{
    //        console.log("resssswqwqqwq",res)
    //     })
    // },[])

    const closeViewModal = () => {
        setViewModalOpen(false);
        setViewOrderData(null);
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleCheckboxChange = (id) => {
        const newSelectedOrders = new Set(selectedOrders);
        if (newSelectedOrders.has(id)) {
            newSelectedOrders.delete(id);
        } else {
            newSelectedOrders.add(id);
        }
        setSelectedOrders(newSelectedOrders);
    };

    const handleSelectAllChange = (e) => {
        if (e.target.checked) {
            const allOrderIds = orders.map(order => order.id);
            setSelectedOrders(new Set(allOrderIds));
        } else {
            setSelectedOrders(new Set());
        }
    };

    return (
        <div className="dashboard-container orderdashboarddetails">
            <h1>Orders Dashboard</h1>

            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search orders"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button onClick={handleSearch}>Search</button>
            </div>

            <h2>Orders</h2>
            {orders.length === 0 ? (
                <p>No orders found.</p>
            ) : (
                <>
                    <table className="orders-table">
                        <thead>
                            <tr>
                                <th><input type="checkbox" onChange={handleSelectAllChange} checked={selectedOrders.size === orders.length} /></th>
                                <th>User Name</th>
                                <th>Affiliate</th>
                                <th>Products</th>
                                <th>DigitalCourse</th>
                                <th>G Amount</th>
                                <th>P Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order) => (
                                <tr key={order.id}>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedOrders.has(order.id)}
                                            onChange={() => handleCheckboxChange(order.id)}
                                        />
                                    </td>
                                    <td>{order.UserName}</td>
                                    <td>{order.AffilateName}</td>
                                    <td>{order.ProductName}</td>
                                    <td>{order.DigitalCourse}</td>
                                    <td>{order.GatewayAmount}</td>
                                    <td>
                                        {order.PaymentStatus === 0 ? 'Not Active' : order.PaymentStatus === 1 ? "Active" : order.PaymentStatus}
                                    </td>
                                    <td>
                                        <FaEdit onClick={() => openEditModal(order)} style={{ fontSize: "18px", cursor: "pointer" }} title='Edit Orders Details' />
                                        <MdDelete onClick={() => handleDelete(order.id)} style={{ fontSize: "18px", cursor: "pointer" }} title='Delete Orders Details' />
                                        <GrView onClick={() => handleView(order.id)} style={{ fontSize: "18px", cursor: "pointer" }} title='View Orders Details' />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button onClick={handleDeleteSelected} className='checkboxdeletebtn'>Delete Selected</button>
                </>
            )}

            <div className="pagination-controls">
                <button onClick={handlePrevious} disabled={currentPage === 1}>Previous</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={handleNext} disabled={currentPage === totalPages}>Next</button>
                <select
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                    className='dropdowndashboard'
                >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>50</option>
                </select>
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                className="react-modal-content_Order"
            >
                <h2>Edit Order</h2>
                <form onSubmit={handleEdit}>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label>User Name:</label>
                            <input
                                className="adminorderpageform"
                                type="text"
                                placeholder="User ID"
                                value={updatedData.UserName}
                                onChange={(e) => setUpdatedData({ ...updatedData, UserName: e.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Product:</label>
                            <input
                                className="adminorderpageform"
                                type="text"
                                placeholder="Products ID"
                                value={updatedData.ProductName}
                                onChange={(e) => setUpdatedData({ ...updatedData, ProductName: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label>Affiliate:</label>
                            <input
                                className="adminorderpageform"
                                type="text"
                                placeholder="Affiliate Name"
                                value={updatedData.AffilateName}
                                onChange={(e) => setUpdatedData({ ...updatedData, AffilateName: e.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>DigitalCourse:</label>
                            <input
                                className="adminorderpageform"
                                type="text"
                                placeholder="Digital Course"
                                value={updatedData.DigitalCourse}
                                onChange={(e) => setUpdatedData({ ...updatedData, DigitalCourse: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <label>Total Amount:</label>
                            <input
                                className="adminorderpageform"
                                type="text"
                                placeholder="Amount"
                                value={updatedData.GatewayAmount}
                                onChange={(e) => setUpdatedData({ ...updatedData, GatewayAmount: e.target.value })}
                            />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label>Status:</label>
                            <input
                                className="adminorderpageform"
                                type="text"
                                placeholder="Payment Status"
                                value={updatedData.PaymentStatus === 1 ? "Active" : updatedData.PaymentStatus === 0 ? "Not Active" : updatedData.PaymentStatus}
                                onChange={(e) => setUpdatedData({ ...updatedData, PaymentStatus: e.target.value })}
                            />
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary">Save</button>
                    <button onClick={closeModal} className="btn btn-secondary" style={{ marginLeft: "5px" }}>Close</button>
                </form>
            </Modal>

            <Modal isOpen={viewModalOpen} className="react-modal-content" onRequestClose={closeViewModal}>
                <h2>View Order Details</h2>
                {viewOrderData && (
                    <table className='adminuser-table'>
                        <tbody>
                            <tr>
                                <th>Book ID:</th>
                                <td>{viewOrderData.book_id}</td> 
                            </tr>
                            <tr>
                              <th> Affiliate ID</th>
                              <td>{viewOrderData.affiliate_id}</td>
                            </tr>
                            <tr>
                                <th>Gatewayamount:</th>
                                <td>{viewOrderData.gatewayamount}</td>
                            </tr>
                            <tr>
                                <th>OrderID:</th>
                                <td>{viewOrderData.id}</td>
                            </tr>
                            <tr>
                                <th>Payment Status:</th>
                                <td>{viewOrderData.paymentstatus}</td>
                            </tr>
                            <tr>
                                <th>ProductID:</th>
                                <td>{viewOrderData.products_id}</td>
                            </tr>
                            <tr>
                                <th>RazorpayId:</th>
                                <td>{viewOrderData.razorpay_order_id}</td>
                            </tr>
                            <tr>
                                <th>Razorpay Paymenty:</th>
                                <td>{viewOrderData.razorpay_payment_id}</td>
                            </tr>
                            <tr>
                                <th>Razorpay_signature:</th>
                                <td>{viewOrderData.razorpay_payment_id}</td>
                            </tr>
                            <tr>
                                <th>Date:</th>
                                <td>{viewOrderData.updatedAt}</td>
                            </tr>
                            <tr>
                                <th>UserID:</th>
                                <td>{viewOrderData.user_id}</td>
                            </tr>
                        </tbody>
                    </table>
                )}
                <button onClick={closeViewModal} className="btn btn-secondary">Close</button>
            </Modal>
        </div>
    );
};
export default AdminOrdersPage;
