import React, { useEffect, useState } from 'react';
import api from '../api/api';
import Modal from 'react-modal';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { GrView } from "react-icons/gr";

const AdminUserPage = () => {
    const [users, setUsers] = useState([]);

    const [editingUserId, setEditingUserId] = useState(null);
  
    const [updatedUserData, setUpdatedUserData] = useState({
        name: '',
        email: '',
        phone: '',
        role: '',
    });
    const [isOpenUserModal, setIsOpenUserModal] = useState(false);
    const [isOpenViewModal, setIsOpenViewModal] = useState(false);
    const [viewUserData, setViewUserData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage, setUsersPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);

    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectedAll, setSelectedAll] = useState(false)

    // Fetch users with pagination
    const fetchUsers = async (page = 1, limit = usersPerPage) => {
        try {
            const { data } = await api.get(`/auth/getAllUsers?page=${page}&limit=${limit}`);
            setUsers(data.data);
            setCurrentPage(data.currentPage);
            setTotalPages(data.totalPages);
        } catch (error) {
            // console.error("Error while getting Users", error);
        }
    };

    useEffect(() => {
        fetchUsers(currentPage, usersPerPage);
    }, [currentPage, usersPerPage]);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const openEditUserModal = (user) => {
        setEditingUserId(user.id);
        setUpdatedUserData({
            name: user.name,
            userid: user.userid,
            email: user.email,
            pass_enc: user.pass_enc,
            phone: user.phone,
            role: user.role,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt,
        });
        setIsOpenUserModal(true);
    };

    const closeUserModal = () => {
        setIsOpenUserModal(false);
        setEditingUserId(null);
        setUpdatedUserData({
            name: '',
            email: '',
            phone: '',
            role: '',
        });
    };

    const openViewUserModal = (user) => {
        setViewUserData(user);
        setIsOpenViewModal(true);
    };

    const closeViewUserModal = () => {
        setIsOpenViewModal(false);
        setViewUserData(null);
    };

    const handleEditUser = async () => {
        try {
            await api.put(`/auth/editUser/${editingUserId}`, updatedUserData);
            setUsers(prev =>
                prev.map(user =>
                    user.id === editingUserId ? { ...user, ...updatedUserData } : user
                )
            );
            closeUserModal();
        } catch (error) {
            // console.error("Error while editing user", error);
        }
    };
 
    const handleDeleteUser = async (userId) => {
        const confirmDelete = window.confirm("Would you like to delete this user? Press Okay or Cancel");
        if (confirmDelete) {
            try {
                await api.delete(`/auth/deleteUser/${userId}`);
                setUsers(prev => prev.filter(user => user.id !== userId));
            } catch (error) {
                // console.error("Error while deleting user", error);
            }
        } else {
            alert('User deletion canceled');
        }
    };

    const handleSearch = async () => {
        if (!searchQuery) {
            fetchUsers();
            return;
        }
        try {
            const { data } = await api.get(`/auth/searchUsers?query=${searchQuery}`);
            setUsers(data.data);
        } catch (error) {
            // console.error("Error while searching users", error);
        }
    };

    const handleView = (user) => {
        openViewUserModal(user);
    };

    const handlePerPageChange = (e) => {
        setUsersPerPage(parseInt(e.target.value, 10));
        setCurrentPage(1);
    };


    //***************selected users
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allUserIds = users.map(user => user.id);
            setSelectedUsers(allUserIds);
        } else {
            setSelectedUsers([]);
        }
    };

    const handleCheckboxChange = (userId) => {
        setSelectedUsers(prev =>
            prev.includes(userId)
                ? prev.filter(id => id !== userId)
                : [...prev, userId]
        );
    };

    const handleBulkDelete = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete selected users?");
        if (confirmDelete && selectedUsers.length > 0) {
            try {
                await Promise.all(
                    selectedUsers.map(userId => api.delete(`/auth/deleteUser/${userId}`))
                );

                setUsers(prev => prev.filter(user => !selectedUsers.includes(user.id)));
                setSelectedUsers([]);
            } catch (error) {
                // console.error("Error while deleting selected users", error);
            }
        }
    };

    return (
        <div className='dashboard-container'>
            <h1>User Dashboard</h1>
            <div className='search-container'>
                <input
                    type="text"
                    placeholder='Search User'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button onClick={handleSearch}>Search</button>
            </div>
            <h2>Users</h2>

            <table className='adminuser-table'>
                <thead>
                    <tr>
                        <th>
                            <input type="checkbox"
                                checked={selectedUsers.length === users.length && users.length > 0}
                                onChange={handleSelectAll}
                            /> Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Create Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.id}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedUsers.includes(user.id)}
                                    onChange={() => handleCheckboxChange(user.id)}
                                /> {user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td>
                            <td>{user.createdAt}</td>
                            <td>
                                <FaEdit onClick={() => openEditUserModal(user)} style={{ fontSize: "18px", cursor: 'pointer' }} title='Edit user'/>
                                <MdDelete onClick={() => handleDeleteUser(user.id)} style={{ fontSize: "18px", cursor: 'pointer' }} title='Delete User'/>
                                <GrView onClick={() => handleView(user)} style={{ fontSize: "18px", cursor: 'pointer' }}title='View User'/>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <button
                className='checkboxdeletebtn'
                onClick={handleBulkDelete} disabled={selectedUsers.length === 0} >Delete Selected</button>
            {/* Pagination Controls */}
            <div className="pagination-controls">
                <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={nextPage} disabled={currentPage === totalPages}>Next</button>

                <label htmlFor="usersPerPage"></label>
                <select id="usersPerPage" value={usersPerPage} onChange={handlePerPageChange}
                    className='dropdowndashboard'
                >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>50</option>
                </select>
            </div>

            {/* Edit User Modal */}
            <Modal
                isOpen={isOpenUserModal}
                onRequestClose={closeUserModal}
                className="react-modal-content"
                overlayClassName="react-modal-overlay"
                contentLabel="Edit User"
            >
                <h2>Edit User</h2>

                <form className="row g-3">
                    <div className="col-md-6">
                        <label htmlFor='name'>Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            id='name'
                            value={updatedUserData.name}
                            onChange={(e) =>
                                setUpdatedUserData({ ...updatedUserData, name: e.target.value })
                            }
                            placeholder="Name"
                        />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor='userid'>User ID:</label>
                        <input
                            type="text"
                            className="form-control"
                            id='userid'
                            value={updatedUserData.userid}
                            onChange={(e) =>
                                setUpdatedUserData({ ...updatedUserData, userid: e.target.value })
                            }
                            placeholder="User ID"
                        />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor='email'>Email:</label>
                        <input
                            type="email"
                            className="form-control"
                            id='email'
                            value={updatedUserData.email}
                            onChange={(e) =>
                                setUpdatedUserData({ ...updatedUserData, email: e.target.value })
                            }
                            placeholder="Email"
                        />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor='pass_enc'>Password:</label>
                        <input
                            type="text"
                            className="form-control"
                            id='pass_enc'
                            value={updatedUserData.pass_enc}
                            onChange={(e) =>
                                setUpdatedUserData({ ...updatedUserData, pass_enc: e.target.value })
                            }
                            placeholder="Password"
                        />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor='phone'>Phone:</label>
                        <input
                            type="tel"
                            className="form-control"
                            id='phone'
                            value={updatedUserData.phone}
                            onChange={(e) =>
                                setUpdatedUserData({ ...updatedUserData, phone: e.target.value })
                            }
                            placeholder="Phone"
                        />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor='createdAt'>Create Date:</label>
                        <input
                            type="text"
                            className="form-control"
                            id='createdAt'
                            value={updatedUserData.createdAt}
                            onChange={(e) =>
                                setUpdatedUserData({ ...updatedUserData, createdAt: e.target.value })
                            }
                        />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor='updatedAt'>Updated Date:</label>
                        <input
                            type="text"
                            className="form-control"
                            id='updatedAt'
                            value={updatedUserData.updatedAt}
                            onChange={(e) =>
                                setUpdatedUserData({ ...updatedUserData, updatedAt: e.target.value })
                            }
                        />
                    </div>

                    <div className="col-md-6">
                        <label htmlFor='role'>Role:</label>
                        <select
                            className="react-modal-content-select"
                            id='role'
                            value={updatedUserData.role}
                            onChange={(e) =>
                                setUpdatedUserData({ ...updatedUserData, role: e.target.value })
                            }
                        >
                            <option value="1">Admin</option>
                            <option value="0">User</option>
                        </select>
                    </div>

                    <div className="col-md-6">
                        <button type="button" className="btn btn-primary" onClick={handleEditUser}>Save Changes</button>
                        <button type="button" className="btn btn-secondary" onClick={closeUserModal}>Cancel</button>
                    </div>
                </form>
            </Modal>
               
            {/* View User Modal */}
            <Modal
                isOpen={isOpenViewModal}
                onRequestClose={closeViewUserModal}
                className="react-modal-content"
                overlayClassName="react-modal-overlay"
                contentLabel="View User"
            >
                <h2>User Details</h2>
                {viewUserData && (
                    <table className='adminuser-table'>
                        <tbody>
                            <tr>
                                <th>Name:</th>
                                <td>{viewUserData.name}</td>
                            </tr>
                            <tr>
                                <th>Email:</th>
                                <td>{viewUserData.email}</td>
                            </tr>
                            <tr>
                                <th>UserId:</th>
                                <td>{viewUserData.userid}</td>
                            </tr>
                            <tr>
                                <th>Phone:</th>
                                <td>{viewUserData.phone}</td>
                            </tr>
                            <tr>
                                <th>Password:</th>
                                <td>{viewUserData.pass_enc}</td>
                            </tr>
                            <tr>
                                <th>Create Date:</th>
                                <td>{viewUserData.createdAt}</td>
                            </tr>
                            <tr>
                                <th>Updated Date:</th>
                                <td>{viewUserData.updatedAt}</td>
                            </tr>
                        </tbody>
                    </table>
                )}
                <button onClick={closeViewUserModal}>Close</button>
            </Modal>
        </div>
    );
};
export default AdminUserPage;
