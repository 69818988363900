import React, { useEffect, useState } from "react";
import CommonButton from "../../commonButton/CommonButton";
import api from "../../../../src/api/api";
import { notification, Modal } from 'antd';
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { CloseOutlined } from '@ant-design/icons';
import { RxCross2 } from "react-icons/rx";

const OrderSummary = ({
  selectedColor,
  selectedColorid,
  selectedBundle,
  discountedPrice,
  bookid,
  currentStep,
  images,
  name,
  onPreOrder,
  handleSubmit,
}) => {

  const navigate = useNavigate();

  const [affiliateCode, setAffiliateCode] = useState("");
  const [finalDiscountedPrice, setFinalDiscountedPrice] = useState(discountedPrice);
  const [isAffiliateVerified, setIsAffiliateVerified] = useState(false);
  const [affiliateId, setAffiliateId] = useState(null);
  const [affiliateDiscount, setAffiliateDiscount] = useState(0);
  const [phonePeModalVisible, setPhonePeModalVisible] = useState(false);
  const [phonePeUrl, setPhonePeUrl] = useState("");

  const handleCloseModal = () => {
    setPhonePeModalVisible(false);
  };

  const id = Cookies.get("id");



  const handleAffiliateVerification = async () => {
    try {
      const response = await api.get(`/affiliate/getAffiliateByCode/${affiliateCode}`);
      const affiliateData = response.data.data;

      if (affiliateData) {
        const newDiscountedPrice = discountedPrice - affiliateData.discount;
        setAffiliateDiscount(affiliateData.discount);
        setFinalDiscountedPrice(newDiscountedPrice > 0 ? newDiscountedPrice : 0);
        setIsAffiliateVerified(true);
        setAffiliateId(affiliateData.id);
        notification.success({ message: "Coupon code verified successfully!" });
      } else {
        notification.error({ message: "Invalid Coupon code. Please correct the code and try again." });
        setFinalDiscountedPrice(discountedPrice);
        setIsAffiliateVerified(false);
        setAffiliateId(null);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        notification.error({ message: "Invalid Coupon code. Please correct the code and try again." });
      } else {
        // console.error("Error verifying affiliate code:", error);
        notification.error({ message: "Error verifying Coupon code. Please try again." });
      }
    }
  };

  const handleRemoveAffiliateCode = () => {
    setAffiliateCode("");
    setFinalDiscountedPrice(discountedPrice);
    setIsAffiliateVerified(false);
    setAffiliateId(null);
    setAffiliateDiscount(0);
  };

  useEffect(() => {
    let newDiscountedPrice = discountedPrice;

    if (isAffiliateVerified) {
      newDiscountedPrice -= affiliateDiscount;
      if (newDiscountedPrice < 0) newDiscountedPrice = 0;
    }
    setFinalDiscountedPrice(newDiscountedPrice);
  }, [selectedBundle, discountedPrice, isAffiliateVerified, affiliateDiscount]);

  // const handlePayment = async () => {
  //   let productColorId = selectedColorid || 1;
  //   if (selectedColor === "gray") {
  //     productColorId = 1;
  //   } else if (selectedColor === "black") {
  //     productColorId = 2;
  //   }

  //   try {
  //     const amountToPay = finalDiscountedPrice ? finalDiscountedPrice : discountedPrice;
  //     const orderResponse = await api.post(`/orders/createOrder`, {
  //       user_id: id,
  //       book_id: bookid,
  //       affiliate_id: affiliateId,
  //       products_id: productColorId,
  //       amount: amountToPay
  //     });

  //     const orderData = orderResponse.data.data.responseOrder;

  //     // console.log("orderDataorderData", orderData);
  //     if (!orderData) {
  //       throw new Error('Order creation failed');
  //     }

  //     if (orderData.paymentmethod === 1) {
  //       const options = {
  //         key: process.env.RAZORPAY_KEY_ID,
  //         amount: orderData.amount,
  //         currency: "INR",
  //         name: name,
  //         description: "Purchase",
  //         image: images,
  //         order_id: orderData.razorpay_order_id,
  //         handler: async function (response) {
  //           try {
  //             const verificationResponse = await api.post(`/orders/verifyPayment`,{
  //               razorpay_order_id: response.razorpay_order_id,
  //               razorpay_payment_id: response.razorpay_payment_id,
  //               razorpay_signature: response.razorpay_signature,
  //             });

  //             if (verificationResponse.data.message  === "Payment verified and order updated successfully") {
  //               Cookies.set("paymentstatus", '1');
  //               navigate("/thank-you");
  //               notification.success({ message:"Payment Successful"});
  //             } else {
  //               notification.error({ message: "Payment verification failed." });
  //               navigate("/payment-failed");
  //             }    
  //           } catch (error) {
  //             notification.error({ message: "Payment verification failed." });
  //           }
  //         },
  //         prefill: {
  //           name: "Version-Next",
  //           email: "versionnext123.com",
  //           contact: "9999999999",
  //         },
  //         theme: {
  //           color: "#3399cc",
  //         },
  //       };

  //       if (window.Razorpay) {
  //         const razorpay = new window.Razorpay(options);
  //         razorpay.open();
  //       } else {
  //         notification.error({ message: "Razorpay script not loaded." });
  //       }
  //     } else if (orderData.paymentmethod === 2) {
  //       setPhonePeUrl(orderData.red_url);
  //       setPhonePeModalVisible(true);
  //       if (orderData.red_url) {
  //         Modal.info({
  //           title: "PhonePe Payment",
  //           content: (
  //             <iframe
  //               src={orderData.red_url}
  //               width="80%"
  //               height="600px"
  //               title="PhonePe Payment"
  //             />
  //           ),
  //           footer: [
  //             <div key="close-btn" style={{ position: 'absolute', top: '10px', right: '24px' }}>
  //               <CloseOutlined
  //                 style={{ fontSize: '24px', color: '#000', cursor: 'pointer' }}
  //                 onClick={() => Modal.destroyAll()}
  //               />
  //             </div>,
  //             <button key="ok-btn" onClick={() => Modal.destroyAll()} style={{ display: 'none' }}>
  //               {orderData.paymentmethod === 2 ? 'Cancel' : 'OK'}
  //             </button>
  //           ],

  //           onOk() {
  //           },
  //           onCancel() { },

  //         });
  //       }
  //     }
  //   } catch (error) {
  //     notification.error({ message: "Something went wrong with the payment." });
  //   }
  // };


  const handlePayment = async () => {
    let productColorId = selectedColorid || 1;
    if (selectedColor === "gray") {
        productColorId = 1;
    } else if (selectedColor === "black") {
        productColorId = 2;
    }

    try {
        const amountToPay = finalDiscountedPrice ? finalDiscountedPrice : discountedPrice;
        const orderResponse = await api.post(`/orders/createOrder`, {
            user_id: id,
            book_id: bookid,
            affiliate_id: affiliateId,
            products_id: productColorId,
            amount: amountToPay,
        });

        const orderData = orderResponse.data.data.responseOrder;

        if (!orderData) {
            throw new Error("Order creation failed");
        }

        if (orderData.paymentmethod === 1) {
            const options = {
                key: process.env.RAZORPAY_KEY_ID,
                amount: orderData.amount,
                currency: "INR",
                name: name,
                description: "Purchase",
                image: images,
                order_id: orderData.razorpay_order_id,
                handler: async (response) => {
                    try {
                        const verificationResponse = await api.post(`/orders/verifyPayment`, {
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_signature: response.razorpay_signature,
                        });

                        // console.log("verificationResponse", verificationResponse.data);

                        if (verificationResponse.data.message === "Payment verified and order updated successfully") {
                            Cookies.set("paymentstatus", "1");
                            notification.success({ message: "Payment Successful" });
                            navigate("/thank-you"); 
                        } else if (verificationResponse.data.message === "Razorpay payment verification failed") {
                            notification.error({ message: "Payment verification failed."});
                            navigate("/payment-failed");
                        }
                    } catch (error) {
                        notification.error({ message: "Payment verification failed." });
                        navigate("/payment-failed");
                    }
                },
                prefill: {
                    name: "Version-Next",
                    email: "versionnext123.com",
                    contact: "9999999999",
                },
                theme: {
                    color: "#3399cc",
                },
            };

            if (window.Razorpay) {
                const razorpay = new window.Razorpay(options);
                razorpay.open();
            } else {
                notification.error({ message: "Razorpay script not loaded." });
            }
        } else if (orderData.paymentmethod === 2) {
            setPhonePeUrl(orderData.red_url);
            setPhonePeModalVisible(true);
            if (orderData.red_url) {
                Modal.info({
                    title: "PhonePe Payment",
                    content: (
                        <iframe
                            src={orderData.red_url}
                            width="80%"
                            height="600px"
                            title="PhonePe Payment"
                        />
                    ),
                    footer: null,
                });
            }
        }
    } catch (error) {
        notification.error({ message: "Something went wrong with the payment." });
    }
};

// const handlePayment = async () => {
//   let productColorId = selectedColorid || 1;
//   if (selectedColor === "gray") {
//       productColorId = 1;
//   } else if (selectedColor === "black") {
//       productColorId = 2;
//   }

//   try {
//       const amountToPay = finalDiscountedPrice ? finalDiscountedPrice : discountedPrice;
//       const orderResponse = await api.post(`/orders/createOrder`, {
//           user_id: id,
//           book_id: bookid,
//           affiliate_id: affiliateId,
//           products_id: productColorId,
//           amount: amountToPay,
//       });

//       const orderData = orderResponse.data.data.responseOrder;

//       if (!orderData) {
//           throw new Error("Order creation failed");
//       }
//       if (orderData.paymentmethod === 1) {
//           const options = {
//               key: process.env.RAZORPAY_KEY_ID,
//               amount: orderData.amount,
//               currency: "INR",
//               name: name,
//               description: "Purchase",
//               image: images,
//               order_id: orderData.razorpay_order_id,
//               handler: async (response) => {
//                 try {
//                     if (
//                         response.razorpay_order_id &&
//                         response.razorpay_payment_id &&
//                         response.razorpay_signature
//                     ) {
//                         // Verify the payment with the backend
//                         const verificationResponse = await api.post(`/orders/verifyPayment`, {
//                             razorpay_order_id: response.razorpay_order_id,
//                             razorpay_payment_id: response.razorpay_payment_id,
//                             razorpay_signature: response.razorpay_signature,
//                         });
            
//                         if (verificationResponse?.data?.success) {
//                             notification.success({ message: "Payment Successful" });
//                             navigate("/thank-you");
//                         } else {
//                             notification.error({ message: verificationResponse?.data?.message || "Payment verification failed." });
//                             navigate("/payment-failed");
//                         }
//                     } else {
//                         // If Razorpay response is missing data
//                         notification.error({ message: "Payment failed due to incomplete data." });
//                         navigate("/payment-failed");
//                     }
//                 } catch (error) {
//                     console.error("Payment verification error:", error);
//                     notification.error({ message: "Payment verification failed. Please try again." });
//                     navigate("/payment-failed");
//                 }
//             },
            
//               prefill: {
//                   name: "Version-Next",
//                   email: "versionnext123.com",
//                   contact: "9999999999",
//               },
//               theme: {
//                   color: "#3399cc",
//               },
//           };

//           if (window.Razorpay) {
//               const razorpay = new window.Razorpay(options);
//               razorpay.open();
//           } else {
//               notification.error({ message: "Razorpay script not loaded." });
//           }
//       } else if (orderData.paymentmethod === 2) {
//           setPhonePeUrl(orderData.red_url);
//           setPhonePeModalVisible(true);
//           if (orderData.red_url) {
//               Modal.info({
//                   title: "PhonePe Payment",
//                   content: (
//                       <iframe
//                           src={orderData.red_url}
//                           width="80%"
//                           height="600px"
//                           title="PhonePe Payment"
//                       />
//                   ),
//                   footer: null,
//               });
//           }
//       }
//   } catch (error) {
//       notification.error({ message: "Something went wrong with the payment." });
//   }
// };









  
  return (
    <>
      <div>
        <div>
          {/* <div className="finalProductImgWRap">
            <img src={images} alt="" className="img-fluid" width={114} height={49} />
          </div> */}
          {/* <div className="nameColrWrap">
            <h2>{name}</h2>
            <p>
              <span>Color:</span> {selectedColor}
            </p>
          </div> */}
        </div>
        {/* <div className="midBoxWp">
          <h2>Course</h2>
          <p>{selectedBundle}</p>
        </div> */}


        {/* main  2*/}

        <div className="orderSummaryproduct2main">

          <div className="orderSummaryproduct2mainChild">
            <div>
              <h2>Discount Code</h2>
              <p className='discountCodeproduct2input'>
                <input
                  type="text"
                  value={affiliateCode}
                  onChange={(e) => setAffiliateCode(e.target.value)}
                  className="order_affiliate"
                  disabled={isAffiliateVerified}
                />
                {!isAffiliateVerified ? (
                  <button
                    onClick={handleAffiliateVerification}
                    className="verify-button"
                    style={{ fontSize: "18px", border: "none" }}
                  >
                    <p className="discountCodeproduct2verifybtn">Verify</p>
                  </button>
                ) : (
                  <button
                    onClick={handleRemoveAffiliateCode}
                    style={{
                      marginLeft: "10px",
                      padding: "7px 10px", cursor: "pointer", borderRadius: "10px", backgroundColor: "red",
                      color: "white"
                    }}
                  >
                    <RxCross2 size={20} />
                  </button>
                )}
              </p>
            </div>

            <div>
              <h2>Total Amount</h2>
              {finalDiscountedPrice < discountedPrice ? (
                <>

                  <h3
                    style={{ fontSize: "3rem" }}
                  >
                    <del>₹{discountedPrice}</del>
                  </h3>
                  <h2> After Discount</h2>
                  <h3
                    style={{ fontSize: "3rem", color: "#2d4cc3" }}
                    className="pricing">₹{finalDiscountedPrice}</h3>
                </>
              ) : (
                <h2
                  style={{ fontSize: "3rem" }}
                  className="pricing">₹{discountedPrice}</h2>
              )}
            </div>
          </div>
          {/*  */}



          {currentStep === 2 ? (
            <form onSubmit={handleSubmit(onPreOrder)}>
              <CommonButton buttonText="Checkout Now" type="submit" onClick={handlePayment} />
            </form>
          ) : (
            <CommonButton buttonText="Pre Order Now" onClick={onPreOrder} type="button" />
          )}




        </div>

      </div>


    </>
  );
};
export default OrderSummary;