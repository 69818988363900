import React  from "react";
import { Toaster} from 'react-hot-toast';
import PageTitle from "../components/pageTitle/PageTitle";
import Layout from "../components/Layout/Layout";
import PragraphBox from "../components/innerpagecomponents/PragraphBox";
import { Helmet } from "react-helmet";

function AboutUs() {
 
  return (
    <Layout>

      <Helmet>
        <title>About Us | Solaris</title>
        <meta name="description" content="Learn about Solaris Book 14X OLED Laptop, India's revolutionary laptop that combines cutting-edge technology with digital learning. Discover our mission to make premium laptops accessible for everyone with lifetime access to digital courses." />
        <meta name="keywords" content="About Solaris, Solaris Book, Mission of Solaris" />
        <meta name="author" content="Solaris Comtech" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://solarisbook.com/about-us" />
        <meta property="og:title" content="About Solaris Book" />
        <meta property="og:description" content="Learn about Solaris Book 14X OLED Laptop, India's revolutionary laptop that combines cutting-edge technology with digital learning. Discover our mission to make premium laptops accessible for everyone with lifetime access to digital courses." />
        <meta property="og:image" content="https://solarisbook.com/Solaris-Book-Laptop.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://solarisbook.com/about-us" />
        <meta name="twitter:title" content="About Solaris Book" />
        <meta name="twitter:description" content="Learn about Solaris Book 14X OLED Laptop, India's revolutionary laptop that combines cutting-edge technology with digital learning. Discover our mission to make premium laptops accessible for everyone with lifetime access to digital courses." />
        <meta name="twitter:image" content="https://solarisbook.com/Solaris-Book-Laptop.png" />
      </Helmet>

      <PageTitle
        gredTitle="About"
        title="Us"
        mainPara="At Solaris Comtech Laptop, we are passionate about innovation and dedicated to delivering
                high-quality technology products. Our flagship product, the Solaris Book, embodies our
                commitment to excellence, cutting-edge design, and user-friendly functionality."
      />
      <div className="boxWrpper">
        <PragraphBox
          contentText="Our Mission:"
          para="Our mission is to revolutionize the tech industry with innovative products that enhance user
                    experiences and contribute to the digital transformation landscape. We strive to create
                    devices that are not only technologically advanced but also sustainable and accessible to
                    everyone."
        />
        <PragraphBox
          contentText="Our Story:"
          para="Solaris Comtech Laptop was founded with a vision to make a significant impact in the
                    technology sector. Inspired by the Make In India initiative, we embarked on a journey to
                    develop and manufacture world-class laptops right here in India. Our team of dedicated
                    engineers, designers, and innovators work tirelessly to bring our vision to life."
        />
       
      </div>
    
      <Toaster position="bottom-left" reverseOrder={false} />
    </Layout>
  );
}

export default AboutUs;
