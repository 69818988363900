import React, { useEffect, useState } from "react";
import { halfLaptop } from "..";
import laptop from './../../images/book/book1.png'
import CommonButton from "../commonButton/CommonButton";
import api from '../../api/api'
import { useNavigate } from "react-router-dom";

function PreOrderSection() {
  const [book, setBook] = useState([])
  const [description, setDescription] = useState([])
  const [sale, setSale] = useState([])
  const [price, setPrice] = useState([])

  useEffect(()=>{
       api.get('/books/getBook/6')
       .then((res)=>{

           setBook(res.data.data.name)
           setDescription(res.data.data.description)
           setPrice(res.data.data.price)
           setSale(res.data.data.saleprice)
       })
       .catch((error)=>{
          console.error(error)
       })
  })


   const navigate = useNavigate()
    
    const orderproduct =()=>{
      navigate('/client-registration');
    }
  
  return (
    <div className="container">
      <div className="sectionTitleBox">
        <h2>Be the earliest to Pre-order Solaris</h2>
        <p>
          By booking, users will unlock lifetime access to over 25 digital
          courses covering diverse topics like programming, photography,
          business, and writing.
        </p>
      </div>
      <div className="row">
        <div className="col-md-10 mx-auto">
          <div className="halfLaptopWrap">
            <img src={halfLaptop} alt="" />
            <div className="bookInputWrap">
              <img src={laptop} alt="" />
            </div>

            <div className="section2bookInputWrap">

              <h2>{book} <span>⭐⭐⭐⭐⭐</span></h2>
              <p>
                {description}.
              </p>
              <div className="section2bookInputWrap-price">
                <span className="discounted-pricee">₹ {sale}/-</span>
                <span className="discounted-price">₹{price}</span>
              </div>
            </div>

            <div className="section2bookInputWrap-commonbtn">
              <CommonButton
                buttonText="Order Now"
                type="button"
                // modal="modal"
                onClick={orderproduct}
                modalId="#staticBackdrop"
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default PreOrderSection;