import React from 'react';
import laptop from '../../assets/images/bannerlaptopaffiliate.png';
import CommonButton from '../commonButton/CommonButton';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProgressBar from './ProgressBar';
import AffiliatePageSection2 from './AffiliatePageSection2';
import AffiliatePageSection3 from './AffiliatePageSection3';
import AffiliatePageSection4 from './AffiliatePageSection4';
import { useNavigate } from 'react-router-dom';
import { Toaster} from 'react-hot-toast';
import useSaleAlert from '../../pages/ShowNotification';
import { Helmet } from 'react-helmet';

const AffiliateProgram = () => {
    const { saleAlert, loading } = useSaleAlert(); 
    const navigate = useNavigate();

    const handleAffiliateBtn = () => {
        navigate('/affiliate-signup');
    };
    const handleAffiliateSignIn =()=>{
         navigate("/affiliate-signin")
    }

    return (
        <>

            <Helmet>
                <title>Affiliate Program | Solaris</title>
                <meta name="description" content="Become a Solaris Affiliate and earn by promoting the Solaris Book and its educational benefits. Partner with us to empower digital learning across India." />
                <meta name="keywords" content="Solaris Affiliate Program, Earn with Solaris, Affiliate Marketing" />
                <meta name="author" content="Solaris Comtech" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://solarisbook.com/affiliate" />
                <meta property="og:title" content="Join the Solaris Affiliate Program | Earn with Us" />
                <meta property="og:description" content="Become a Solaris Affiliate and earn by promoting the Solaris Book and its educational benefits. Partner with us to empower digital learning across India." />
                <meta property="og:image" content="https://solarisbook.com/Solaris-Book-Laptop.png" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content="https://solarisbook.com/affiliate" />
                <meta name="twitter:title" content="Join the Solaris Affiliate Program | Earn with Us" />
                <meta name="twitter:description" content="Become a Solaris Affiliate and earn by promoting the Solaris Book and its educational benefits. Partner with us to empower digital learning across India." />
                <meta name="twitter:image" content="https://solarisbook.com/Solaris-Book-Laptop.png" />
            </Helmet>

            <section className="affiliatebg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 affiliatesec1main" style={{ marginTop: "100px" }}>
                            <p className="affiliatesec1-para1">
                                Become an <span className="span-1">Affiliate</span> for Solarisbook
                            </p>
                            <p className="affiliatespan2">
                                India's pioneering crafting laptop book-tailored for lifelong learning and suitable for all ages!
                            </p>
                            <div className="banner-affiliate-btn">
                                <div>
                                <CommonButton
                                    style={{ cursor: 'pointer' }}
                                    onClick={handleAffiliateBtn}
                                    buttonText="Join Now"
                                />
                                </div>

                                <div>
                                <CommonButton
                                    style={{cursor:'pointer' }}
                                    onClick={handleAffiliateSignIn}
                                    buttonText="SignIn"
                                />
                                </div>
                                
                            </div>
                        </div>
                     
                        <div className="col-md-6 text-center affiliateTv">
                            <img src={laptop} alt="bannerlaptop" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <span className='affiliateprogressmain'>
                    <ProgressBar className='affiliateprogress' />
                </span>
            </section>
            <AffiliatePageSection2 />
            <AffiliatePageSection3 />
            <AffiliatePageSection4 />
            <Toaster position="bottom-left" reverseOrder={false} /> 
        </>
    );
};

export default AffiliateProgram;
