import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import "../src/assets/scss/main.css";
import HomePage from "./pages/HomePage";
import SupportPage from "./pages/SupportPage";
import AboutUs from "./pages/AboutUs";
import Modal from "./components/modal/Modal";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import PaymentSuccessModal from "./components/modal/productdetails/PaymentSuccessModel";
import Login from "./components/Auth/Login";
import { AuthProvider } from './components/Auth/AuthContext';
import ProtectedRoute from './components/Auth/ProtectedRouter';
import PaymentFailed from "./components/modal/productdetails/PaymentFailed";
import MyAccount from "./components/MyAccount/MyAccount";
import AffiliatePage from "./components/AffiliatePage/AffiliatePage";
import AffiliateSignup from "./components/AffiliatePage/AffiliateSignup";
import AffiliateSignIn from "./components/AffiliatePage/AffiliateSignIn";
import AffiliateDetails from "./components/AffiliatePage/AffiliateDetails";
import ProtectRoute from "./components/Auth/ProtectRoute";
import Protect from './components/Auth/Protect';
import Dashboard from "./mainDashboard/Dashboard";
import Shipping from "./pages/Shipping";
import Refund from "./pages/Refund";
import AffiliateView from "./dashboard/AffiliatePage/AffiliateView";
import AffiliateEdit from "./dashboard/AffiliatePage/AffiliateEdit";
import NotFound from './404 Page/404Page';
import ResetPassword from './ResetPassword/ResetPassword';
import ClientRegistration from './components/Registration/ClientRegistration';
import NewProductDetails from './components/modal/newProductdetails/NewProductDetails';

const AppContent = () => {
  const location = useLocation();
  const isDashboardRoute = location.pathname.startsWith('/dashboard');

  return (
    <>
      {!isDashboardRoute && <Navbar/>}
      <Routes> 
        <Route path="/" element={<HomePage />} />
        <Route path="/check-out" element={<NewProductDetails />}/>
        <Route path="/client-registration" element={<ClientRegistration />}/>
        <Route path="/support" element={<SupportPage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/payment-failed" element={<PaymentFailed />} />
        <Route path="/login" element={<Login />} />
        <Route path="/affiliate" element={<AffiliatePage />} />
        <Route path="/thank-you" element={<PaymentSuccessModal />} />

        <Route path="/my-account" element={<Protect><MyAccount /></Protect>} />
        <Route path="/affiliate-signup" element={<AffiliateSignup />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/affiliate/edit/:id" element={<AffiliateEdit />} />
        <Route path="/affiliate/view/:id" element={<AffiliateView />} />
        <Route path="/dash" element={<Dashboard />} />
        <Route path="/affiliate-signin" element={<AffiliateSignIn />} />
        <Route path="/affiliate-details" element={<ProtectRoute><AffiliateDetails /></ProtectRoute>} />
        <Route path="/dashboard/*" element={<ProtectedRoute> <Dashboard /></ProtectedRoute>} />
        <Route path="*" element={<NotFound />} />
      </Routes>  
      {!isDashboardRoute && <Footer />}
      <Modal />
    </>
  );
};

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ScrollToTop />
        <AppContent />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
